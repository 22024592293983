import {ofType} from 'redux-observable'
import {BehaviorSubject} from 'rxjs'
import {mapTo, take, tap} from 'rxjs/operators'
import {setId, startSetIdAsync} from '../slices/user-slice'

export const stopSetIdAsync$ = new BehaviorSubject(false)

export const setIdAsyncEpic = action$ =>
  action$.pipe(
    ofType(startSetIdAsync.type),
    take(1),
    mapTo(setId(localStorage.getItem("token"))),
    tap({
      complete: () => {
        stopSetIdAsync$.next(true)
      },
    })
  )
