import { createAction, createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
  churchLoading: true,
  servicesLoading: true,
  favoriteLoading: true,
  services: [],
  church: null,
}

export const startLoading = createAction('church/startLoading')
export const setIsLikedAsync = createAction('church/set-is-liked-async')

export const selectServices = state => state.church.services

export const selectOrders = createSelector(selectServices, services =>
  services.filter(s => s.choice === (localStorage.getItem("language") === "ua" ? 'Замовити' : 'Request'))
)

export const selectSupports = createSelector(selectServices, services =>
  services.filter(s => s.choice === (localStorage.getItem("language") === "ua" ? 'Підтримати' : 'Donate'))
)

export const churchSlice = createSlice({
  name: 'church',
  initialState,
  reducers: {
    setChurchLoading(state, { payload }) {
      state.churchLoading = payload
    },
    setServicesLoading(state, { payload }) {
      state.servicesLoading = payload
    },
    setFavoriteLoading(state, { payload }) {
      state.favoriteLoading = payload
    },
    setServices(state, { payload }) {
      state.services = payload
    },
    setChurch(state, { payload }) {
      state.church = payload
    },
    setIsLiked(state, { payload }) {
      state.church = { ...state.church, isLiked: payload }
    },
  },
})

export const {
  setServices,
  setServicesLoading,
  setFavoriteLoading,
  setChurchLoading,
  setChurch,
  setIsLiked,
} = churchSlice.actions

export default churchSlice.reducer
