import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveToken } from "../../api/messagingAPI";

const firebaseConfig = {
    apiKey: "AIzaSyC60VYJgdALsN3bQWgvdaJ3qyayZVS2CXs",
    authDomain: "religionpeace-57e0a.firebaseapp.com",
    projectId: "religionpeace-57e0a",
    storageBucket: "religionpeace-57e0a.appspot.com",
    messagingSenderId: "160836794618",
    appId: "1:160836794618:web:766d028d6cd04b0063f143",
    measurementId: "G-EV25DFRWD9"
};

const messaging = (async () => {
    if ("Notification" in window) {
        initializeApp(firebaseConfig)
        localStorage.setItem("firebaseSupported", "true")
        return getMessaging();
    }else{
        localStorage.setItem("firebaseSupported", "false")
    }
})();

// export const requestForToken = () => {
//     return getToken(messaging, {vapidKey: "BI65LoiTDlC8ws3NfUm6hpLOgkDRU98U7lSgss_sgFwxcFUOl5bhCvI918AeFQj21A6fUKGRlC6LcaxFx7_xVww"})
//         .then((currentToken) => {
//             if (currentToken) {
//                 saveToken(currentToken).finally(() => {
//                     localStorage.setItem("token", currentToken)
//                 })
//                 console.log('current token for client: ', currentToken);
//             } else {
//                 console.log('No registration token available. Request permission to generate one.');
//             }
//         })
//         .catch((err) => {
//             let currentToken = "mock" + Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
//             saveToken(currentToken).finally(() => {
//                 localStorage.setItem("token", currentToken)
//             })
//             console.log('current token for client: ', currentToken);
//             console.log('An error occurred while retrieving token.', err);
//         });
// };

// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             console.log(payload)
//             resolve(payload);
//     });
// });

export const onMessageListener = async () => {
    try{
        new Promise((resolve) =>
            (async () => {
                const messagingResolve = await messaging;
                if(messagingResolve) {
                    onMessage(messagingResolve, (payload) => {
                        resolve(payload);
                    });
                }
            })()
        );
        localStorage.setItem("firebaseSupported", "true")
    }catch(err){
        localStorage.setItem("firebaseSupported", "false")
    }
}

export const requestForToken = async () => {
    const messagingResolve = await messaging;
    getToken(messagingResolve, {vapidKey: "BI65LoiTDlC8ws3NfUm6hpLOgkDRU98U7lSgss_sgFwxcFUOl5bhCvI918AeFQj21A6fUKGRlC6LcaxFx7_xVww"})
        .then(currentToken => {
            if(localStorage.getItem("token") !== currentToken){
                saveToken(currentToken).finally(() => {
                    localStorage.setItem("token", currentToken)
                })
            }else{
                console.log('current token for client: ', currentToken);
            }
        }).catch(() => {
        console.log('No registration token available. Request permission to generate one.');
    })
};

