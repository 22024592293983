import { batchActions } from 'redux-batched-actions'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { filter, map, mergeMap, tap } from 'rxjs/operators'
import { getChurch } from '../../api/churchAPI'
import { getFavoriteDetail, like } from '../../api/favoriteAPI'
import { getServices } from '../../api/serviceAPI'
import {
  setChurch,
  setChurchLoading,
  setFavoriteLoading,
  setIsLiked,
  setIsLikedAsync,
  setServices,
  setServicesLoading,
  startLoading,
} from '../slices/church-slice'
import { stopSetIdAsync$ } from './user-epics'

export const setLoadingEpic = (action$, state$) =>
  action$.pipe(
    ofType(startLoading.type),
    mergeMap(({ payload: churchId }) =>
      merge(
        of(
          batchActions([setServicesLoading(true), setChurchLoading(true), setFavoriteLoading(true)])
        ),
        fromPromise(getServices(churchId)).pipe(
          map(services => batchActions([setServicesLoading(false), setServices(services)]))
        ),
        fromPromise(getChurch(churchId)).pipe(
          map(church => batchActions([setChurchLoading(false), setChurch(church)]))
        ),
        stopSetIdAsync$.pipe(
          filter(value => value),
          mergeMap(() =>
            fromPromise(getFavoriteDetail(churchId, state$.value.user.id)).pipe(
              map(response =>
                batchActions([
                  setFavoriteLoading(false),
                  setIsLiked(
                    response.church?.uuid === churchId &&
                      response.device_id === localStorage.getItem("token")
                  ),
                ])
              )
            )
          )
        )
      )
    )
  )

export const setIsLikedAsyncEpic = (action$, state$) =>
  action$.pipe(
    ofType(setIsLikedAsync.type),
    mergeMap(() => {
      const isLiked = !state$.value.church.church.isLiked

      return of(setIsLiked(isLiked)).pipe(
        tap(() => {
          like(state$.value.church.church.uuid, state$.value.user.id, isLiked)
        })
      )
    })
  )
