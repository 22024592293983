import React, {useEffect, useState} from 'react';
import {NavLink as RouterLink, useHistory, useLocation} from "react-router-dom";
import logo from "../assets/logo.svg";
import {Button, Grid, Link, makeStyles, TextField, Typography, useMediaQuery} from "@material-ui/core";
import {fetchMoreChurches, setInitialState} from "../store/slices/churches-list-slice";
import {useDispatch, useSelector} from "react-redux";
import {object, string} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {confirmSyncDevice, requestForSyncDevice} from "../api/synchronizationAPI";
import qs from "qs";
import {login} from "../store/slices/user-slice";
import BackIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(() => ({
    backStage: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        // backgroundColor: theme.palette.primary.main
        backgroundColor: 'white',
        maxWidth: '99vw'
    },
    inputBox: {
        borderRadius: '15px',
        margin: '0px auto',
        backgroundColor: 'white',
        //mborder: `2px solid ${theme.palette.primary.main}`,
        // backgroundColor: theme.palette.primary.main,
        textAlign: 'center',
        height: '100px'
    },
    logo: {
        width: 230,
        height: 150,
        objectFit: 'cover'
    },
    inputField: {
        borderRadius: '25px'
    },
    modalActions: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalForm: {
        borderRadius: '20px',
        textAlign: 'center',
        color: 'black',
    },
    title: {
        padding: 0,
        marginTop: '15px',
    },
    skipLabel: {
        cursor: 'pointer',
        margin: '20px 0px',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    authText: {
        marginTop: '20px',
        fontSize: "19px",
        '@media(max-width: 600px)': {
            fontSize: '17px'
        },
        '@media(max-width: 460px)': {
            fontSize: '15px'
        }
    }
}))

const Auth = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isAdmit, setIsAdmit] = useState(false)
    const [error, setIsError] = useState(false)
    const [message, setErrorMessage] = useState('Неправильний код')
    const mdUp = useMediaQuery((theme => theme.breakpoints.up("md")))
    const initLanguage = useSelector(state => state.user.isLanguageLoaded)
    const language = useSelector(state => state.language.language)
    const authorized = useSelector(state => state.user.authorized)
    const history = useHistory()
    const { search } = useLocation()

    useEffect(() => {
        const { sync_redirect_email } = qs.parse(search.replace(/^\?/, ''))

        if(authorized) {
            history.push("/")
        }

        if(sync_redirect_email){
            setIsAdmit(true)
            setValue("email", sync_redirect_email)
        }
    }, [authorized])

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue
    } = useForm({
        reValidateMode: "onSubmit",
        defaultValues: {
            email: '',
            code: '',
        },
        resolver: yupResolver(object({
                email: string()
                    .required(language === "ua" ? `Обов'язкове поле` : 'Required field')
                    .email(language === "ua" ? 'Електронна пошта некоректна' : 'Invalid email'),
                code: string().matches(/^[0-9]{5}$/, {
                    message: language === "ua" ? 'Код має бути 5-ти значним та складатися лише з цифр' : 'Invalid authorization code',
                    excludeEmptyString: true,
                }),
            })
        ),
    })

    const handleMainPageLinkClick = () => {
        dispatch(setInitialState())
        dispatch(fetchMoreChurches())
        history.goBack()
    }

    const handleSync = ({email, code}) => {
        if(!isAdmit){
            requestForSyncDevice(email, localStorage.getItem("token"))
                .then(() => {
                    setIsAdmit(true)
                })
        }
        else {
            if(code === "") {
                setIsError(true)
            }else{
                confirmSyncDevice(email, localStorage.getItem("token"), code)
                    .then(() => {
                        history.push("/?sync_success=true")
                        dispatch(login(email))
                    }).catch(() => {
                    setIsError(true)
                    setErrorMessage(language === "ua" ? "Неправильний код."
                        : "Invalid authorization code")
                })
            }
        }
    }

    return (<>
        {initLanguage && (
            <Grid container spacing={2} className={classes.backStage} style={{height: '100vh'}}>
                <div style={{position: 'absolute', left: 10, top: 8, display: 'flex', alignItems: 'center'}}
                     onClick={() => {
                         history.goBack()
                     }}>
                    <BackIcon fontSize={"small"} style={{color: '#f87f30'}} />
                    <Typography style={{color: '#f87f30', cursor: 'pointer', fontSize: '17px'}}>
                        {language === "ua" ? "Назад" : "Back"}
                    </Typography>
                </div>
                <Grid item xs={12} style={{display: 'flex', flexFlow: 'column', alignItems: 'center', flexBasis: '20%', marginTop: '30px'}}>
                    <Link
                        component={RouterLink}
                        to={'/'}
                        onClick={handleMainPageLinkClick}
                        style={{position: 'relative'}}
                    >
                        <img src={logo} alt="logo" className={classes.logo} />
                    </Link>
                </Grid>
                <Grid item xs={mdUp ? 4 : 11} className={classes.inputBox}>
                    <Typography variant={mdUp ? "h6" : "h4"} style={{marginTop: '10px', fontWeight: 600}}>
                        {language === "ua" ? "Вітаємо!" : "Welcome!"}
                    </Typography>
                    {isAdmit ? (
                        <Typography className={classes.authText}>
                            {language === "ua" ? `Введіть Код авторизації та натисніть на кнопку "Увійти".`
                                : `Enter the Authorization code and click on the "Log in" button.`}
                        </Typography>
                    ) : (
                        <Typography className={classes.authText}>
                            {language === "ua" ?
                                `Щоб полегшити користування веб-сайтом, введіть свою електронну пошту та натисніть на кнопку "Отримати код”. Після цього на електронну пошту прийде код та інструкція, щоб завершити процес авторизації.`
                                : `To make the website easier to use, enter your email and click on the "Get Code" button. After that, a code and instructions will be sent to your email to complete the authorization process.`}
                        </Typography>
                    )}
                    <form noValidate onSubmit={handleSubmit(handleSync)}>
                        <TextField
                            fullWidth
                            type="email"
                            {...register("email")}
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                            placeholder={language === "ua" ? "Електронна пошта" : "Email"}
                            className={classes.inputField}
                            style={{marginTop: isAdmit ? '20px' : '40px'}}
                            inputProps={{
                                readOnly: isAdmit
                            }}
                        />
                        {isAdmit && (
                            <TextField
                                fullWidth
                                placeholder={language === "ua" ? "Код авторизації" : "Authorization code"}
                                className={classes.inputField}
                                style={{marginTop: '20px'}}
                                {...register("code")}
                                error={!!errors?.code}
                                helperText={errors?.code?.message}
                                onClick={() => {
                                    if (error) {
                                        setIsError(false)
                                    }
                                }}
                            />
                        )}
                        {error === true ? (
                            <Typography color={"error"}>
                                {message}
                            </Typography>
                        ) : null}
                        <Button style={{marginTop: '20px'}}
                                type={"submit"}>
                            {language === "ua" ? (isAdmit ? "Увійти" : "Отримати код") : (isAdmit ? "Log in" : "Get code")}
                        </Button>
                    </form>
                    <div
                        onClick={handleMainPageLinkClick}
                    >
                        <Typography className={classes.skipLabel}>
                            {language === "ua" ? (!isAdmit ? "Пропустити" : "Відмінити") : (!isAdmit ? "Skip" : "Cancel")}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )}
    </>
    );
}

export default Auth;
