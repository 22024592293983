import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 1,
    background: 'radial-gradient(ellipse at center,#fc9011 0,hsla(0,0%,100%,0) 75%)',
  },
}))

const Divider = ({ style }) => {
  const classes = useStyles()

  return <div style={style} className={classes.root} />
}

export default Divider
