import {
  ClickAwayListener,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import React, {memo, useState} from 'react'
import {Scrollbars} from 'react-custom-scrollbars'
import {useDispatch, useSelector} from 'react-redux'
import {setRadiusAsync} from '../store/slices/churches-list-slice'
import NearMeIcon from '@material-ui/icons/NearMe';

const useStyles = makeStyles(theme => ({
  menuItem: {
    '&:hover': {
      color: 'orange',
      background: 'none',
    },
    '&$selected': {
      borderRadius: '1rem',
      background: '#fc9011',
    },
  },
  menuList: {
    boxSizing: 'border-box',
    padding: '0.6rem',
    minWidth: '140px',
  },
  selected: {},
}))

const FindChurchesByRadiusButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const radius = useSelector(state => state.churchesList.radius)
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const classes = useStyles()
  const language = useSelector(state => state.language.language)

  const onSelect = value => {
    setAnchorEl(null)
    dispatch(setRadiusAsync(value))
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <IconButton
        color={'primary'}
        variant={'contained'}
        size={smUp ? 'medium' : 'small'}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}
      >
        <NearMeIcon fontSize={"medium"} style={{color: 'white', background: '#f87f30', borderRadius: '50%', padding: '6px 6px 4px'}}/>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          modifiers={{ flip: { enabled: false } }}
          disablePortal
        >
          <Paper style={{ borderRadius: '1rem' }}>
            <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={'50vh'}>
              <MenuList className={classes.menuList}>
                {new Array(11).fill(0).map((el, i) => (
                  <MenuItem
                    onClick={() => onSelect(i)}
                    key={i}
                    selected={radius === i}
                    classes={{ root: classes.menuItem, selected: classes.selected }}
                  >
                    {i === 0 ? language === "ua" ? 'Показати всі' : "Show All" : `${i} ${language === "ua" ? 'км' : 'km'}`}
                  </MenuItem>
                ))}
              </MenuList>
            </Scrollbars>
          </Paper>
        </Popper>
      </IconButton>
    </ClickAwayListener>
  )
}

export default memo(FindChurchesByRadiusButton)
