import React, {useState, useEffect} from 'react'
import { requestForToken, onMessageListener } from './firebase';
import {Button, IconButton, makeStyles, Snackbar, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useHistory} from "react-router-dom";
import {getVisitorId} from "../fingerprint";
import {saveToken} from "../../api/messagingAPI";

const useStyles = makeStyles({
    snackbar: {
        backgroundColor: 'white',
        width: '40%',
        border: '2px solid #f76c11',
        borderRadius: '15px',
        margin: '0 auto',
        '@media(max-width: 600px)':{
            width: '70%'
        }
    },
    block: {
        width: '100%',
        height: '7%',
        textAlign: 'center',
        position: 'relative',
        padding: '20px'
    }
})


const Notification = () => {
    const classes = useStyles()
    const [notification, setNotification] = useState({title: '', body: '', link: '', isLive: ''});
    const [isOpen, setOpen] = useState(false)
    const [isPush, setIsPush] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (notification?.title ){
            setOpen(true)
        }
    }, [notification])

    useEffect(() => {
        if(localStorage.getItem("firebaseSupported") === "true"){
            requestForToken()
            onMessageListener()
                .then((payload) => {
                    if(payload?.data?.mailing){
                        setIsPush(true)
                    }
                    setNotification({title: payload?.notification?.title, body: payload?.notification?.body, link: payload?.data?.link_url, isLive: payload?.data?.is_live});
                })
                .catch((err) => console.log('failed: ', err));
        }else{
            getVisitorId().then(response => {
                if(localStorage.getItem("token") !== response) {
                    saveToken(response).finally(() => {
                        localStorage.setItem("token", response)
                    })
                }
            })
        }

    }, [])

    const handleMoveToStream = () => {
        setOpen(false)
        history.push(notification?.link.replace("https://religionpeace.live/", "/"))
    }

    if(localStorage.getItem("customer_role") === "pre-client") {
        localStorage.setItem("customer_role", "client")
        return null
    }

    if(localStorage.getItem("customer_role") === "host") return null

    return (
        <Snackbar open={isOpen}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                      vertical: "top",
                      horizontal: 'center'
                  }}
                  className={classes.snackbar}
        >
            <div className={classes.block}>
                <Typography><b>{notification?.title}</b></Typography>
                <Typography>{notification?.body}</Typography>
                {notification?.isLive === "True" && (
                    <div>
                        <Button style={{marginTop: '10px'}} onClick={handleMoveToStream}>
                            {isPush ? "Переглянути" : "Приєднатися"}
                        </Button>
                    </div>
                )}
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)} style={{position: 'absolute', top: 3, right: 3}}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
        </Snackbar>
    )
}

export default Notification
