import { batchActions } from 'redux-batched-actions'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { filter, map, mergeMap } from 'rxjs/operators'
import { getFavoriteChurches } from '../../api/favoriteAPI'
import {
  fetchFavoriteChurches,
  setChurches,
  setLoading,
} from '../slices/favorite-churches-list-slice'
import { stopSetIdAsync$ } from './user-epics'

export const fetchFavoriteChurchesEpic = (action$, state$) =>
  action$.pipe(
    ofType(fetchFavoriteChurches.type),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        stopSetIdAsync$.pipe(
          filter(value => value),
          mergeMap(() =>
            fromPromise(getFavoriteChurches(state$.value.user.id)).pipe(
              map(results =>
                batchActions([setLoading(false), setChurches(results.map(item => item.church))])
              )
            )
          )
        )
      )
    )
  )
