import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  churches: [],
  page: 1,
  hasMore: true,
  loading: true,
  term: '',
  scrollPosition: 0,
  radius: 0,
}

export const fetchMoreChurches = createAction('churches-list/fetch-more-churches')
export const debouncedFetchMoreChurches = createAction('churches-list/debounced-fetch-more-churches')
export const setTermAsync = createAction('churches-list/set-term-async')
export const setRadiusAsync = createAction('churches-list/set-radius-async')

export const churchesListSlice = createSlice({
  name: 'churches-list',
  initialState,
  reducers: {
    setRadius(state, { payload }) {
      state.radius = payload
    },
    setChurches(state, { payload }) {
      state.churches = payload
    },
    addChurches(state, { payload }) {
      state.churches.push(...payload)
    },
    incrementPage(state) {
      state.page++
    },
    setInitialPage(state) {
      state.page = 1
    },
    setHasMore(state, { payload }) {
      state.hasMore = payload
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setTerm(state, { payload }) {
      state.term = payload
    },
    setScrollPosition(state, { payload }) {
      state.scrollPosition = payload
    },
    setInitialState() {
      return initialState
    },
  },
})

export const {
  addChurches,
  setLoading,
  setTerm,
  setHasMore,
  incrementPage,
  setScrollPosition,
  setRadius,
  setChurches,
  setInitialState,
} = churchesListSlice.actions

export default churchesListSlice.reducer
