import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    color: '#273444',
    background: '#eff2f7',
    padding: `0.35em 0.875em`,
    borderRadius: '50rem',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 13,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    lineHeight: '40px',
  },
  secondTheme: {
    color: '#f76c11',
    background: '#fde2cf',
  },
}))

const Chip = forwardRef(({ style, className, children, secondTheme, ...props }, ref) => {
  const classes = useStyles()

  return (
    <div
      ref={ref}
      {...props}
      style={style}
      className={clsx(classes.root, secondTheme && classes.secondTheme, className)}
    >
      {children}
    </div>
  )
})

export default Chip
