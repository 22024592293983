import { combineEpics } from 'redux-observable'
import { setIsLikedAsyncEpic, setLoadingEpic } from './church-epics'
import {
  debouncedFetchMoreChurchesEpic,
  fetchMoreChurchesEpic,
  setRadiusAsyncEpic,
  setTermAsyncEpic,
} from './churches-list-epics'
import { fetchFavoriteChurchesEpic } from './favorite-churches-list-epics'
import { setIdAsyncEpic } from './user-epics'

const rootEpic = combineEpics(
  debouncedFetchMoreChurchesEpic,
  fetchMoreChurchesEpic,
  setTermAsyncEpic,
  setRadiusAsyncEpic,
  setIdAsyncEpic,
  setLoadingEpic,
  setIsLikedAsyncEpic,
  fetchFavoriteChurchesEpic
)

export default rootEpic
