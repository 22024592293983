import {
    AppBar,
    Badge,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink as RouterLink, Route, useHistory} from 'react-router-dom'
import logo from '../assets/logo.svg'
import {fetchMoreChurches, setInitialState} from '../store/slices/churches-list-slice'
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import {getNewNotifications} from "../api/messagingAPI";
import {setCountNewNotifications} from "../store/slices/notifications-slice";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {logout} from "../store/slices/user-slice";
import {deleteAccount, deSyncDevice} from "../api/synchronizationAPI";
import {setLanguage} from "../store/slices/language-slice";
import LanguageIcon from '@material-ui/icons/Language';
import FindChurchesByRadiusIcon from "./FindChurchesByRadiusIcon";
import {setUserLanguage} from "../api/languageAPI";

const routes = [
  { to: '/', name: 'Головна', exact: true },
  { to: '/favorite', name: 'Мої громади', exact: true },
  { to: '/live', name: 'Пряма трансляція', exact: false },
  { to: '/balance', name: 'Баланс добра', exact: true },
  { to: '/notifications', name: 'Сповіщення', exact: false },
  { to: '/about', name: 'Про нас', exact: true },
  { to: '/contact', name: 'Контакти', exact: true },
]

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: 'Nunito Sans',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
    cursor: 'pointer'
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  drawerPaper: {
    width: '100%',
  },
  toolbar: {
    height: 90,
  },
  logo: {
    width: 125,
    height: 125,
    objectFit: 'cover',
    '@media(max-width: 400px)': {
        width: 100,
        height: 100,
   },
  },
  menuIconButton: {
    position: 'absolute',
    paddingRight: '10px',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        width: '250px',
        margin: '5px'
    },
    '@media(min-width: 600px)': {
       width: '300px',
       margin: '5px'
    },
    '@media(max-width: 400px)': {
       width: '220px',
       margin: '5px'
    },
  },
  menuSign: {
    padding: 0
  },
  placeholder: {
    height: 90,
  },
  badgeCounter: {
     color: 'white',
  },
  notifyIcon: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover' : {
        transform: 'scale(1.03)'
    },
    '&:active': {
        backgroundColor: 'transparent'
    },
  },
  accountIcon: {
      cursor: 'pointer',
      marginRight: '3px',
      '&:hover' : {
          transform: 'scale(1.03)'
      },
      '@media(max-width: 400px)': {
          display: 'none'
      }
  },
    icons: {
        color: 'white',
        background: '#fc9011',
        borderRadius: '50%',
        padding: '5px'
    },
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    modalContent: {
        paddingTop: '4px',
        paddingBottom: 0,
    },
    modalText: {
        marginBottom: '5px',
    },
    modalActions: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        columnGap: '10px'
    },
    modalForm: {
        borderRadius: '20px',
        textAlign: 'center',
        color: 'black',
        minWidth: '250px'
    },
    title: {
        padding: 0,
        marginTop: '15px',
    },
    confirmButton: {
        background: '#ee4253',
        transition: '0.2s',
        '&:hover, &:active': {
            background: '#f32741',
        },
    },
    deleteLabel: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 600
    }
}))

function getExitSuccessDialog(openSuccessLogout, setSuccessLogoutOpen, classes, isUkrainian) {
    return <Dialog
        open={openSuccessLogout}
        onClose={() => {
            setSuccessLogoutOpen(false)
        }}
        classes={{paper: classes.modalForm}}
        PaperProps={{
            style: {minWidth: '300px'}
        }}
    >
        <DialogTitle classes={{root: classes.title}}>ReligionPeace Live</DialogTitle>
        <div style={{
            marginTop: "10px",
            marginBottom: "5px",
            height: 2,
            background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
        }}/>
        <DialogContent>
            <Typography>
                {isUkrainian ? "Ви успішно вийшли." : "You have successfully logged out."}
            </Typography>
        </DialogContent>
        <DialogActions classes={{root: classes.modalActions}}>
            <Button
                color={"primary"}
                onClick={() => setSuccessLogoutOpen(false)}
            >
                {isUkrainian ? "Ок" : 'Ok'}
            </Button>
        </DialogActions>
    </Dialog>;
}

function getSuccessDeleteDialog(openSuccessDelete, dispatch, setSuccessDeleteOpen, classes, userEmail, isUkrainian) {
    return <Dialog
        open={openSuccessDelete}
        onClose={() => {
            dispatch(logout())
            setSuccessDeleteOpen(false)
        }}
        classes={{paper: classes.modalForm}}
        PaperProps={{
            style: {minWidth: '300px'}
        }}
    >
        <DialogTitle classes={{root: classes.title}}>ReligionPeace Live</DialogTitle>
        <div style={{
            marginTop: "10px",
            marginBottom: "5px",
            height: 2,
            background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
        }}/>
        <DialogContent>
            <Typography>
                {isUkrainian ?
                    `Ви успішно видалили обліковий запис ${userEmail}` :
                    `You have successfully deleted your account ${userEmail}`}
            </Typography>
        </DialogContent>
        <DialogActions classes={{root: classes.modalActions}}>
            <Button
                color={"primary"}
                onClick={() => {
                    dispatch(logout())
                    setSuccessDeleteOpen(false)
                }}
            >
                {isUkrainian ? "Ок" : 'Ok'}
            </Button>
        </DialogActions>
    </Dialog>;
}

function getConfirmDialog(openDelete, setOpenDelete, classes, userEmail, handleDeleteAccount, isUkrainian) {
    return <Dialog
        open={openDelete}
        onClose={() => {
            setOpenDelete(false)
        }}
        classes={{paper: classes.modalForm}}
        PaperProps={{
            style: {minWidth: '300px'}
        }}
    >
        <DialogTitle classes={{root: classes.title}}>ReligionPeace Live</DialogTitle>
        <div style={{
            marginTop: "10px",
            marginBottom: "5px",
            height: 2,
            background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
        }}/>
        <DialogContent>
            <Typography>
                {isUkrainian ?
                    `Ви дійсно бажаєте видалити обліковий запис ${userEmail} ?` :
                    `Do you really want to delete the account ${userEmail} ?`}
            </Typography>
        </DialogContent>
        <DialogActions classes={{root: classes.modalActions}}>
            <Button
                color={"primary"}
                onClick={() => {
                    setOpenDelete(false)
                }}
            >
                {isUkrainian ? "Скасувати" : "Cancel"}
            </Button>
            <Button
                className={classes.confirmButton}
                onClick={handleDeleteAccount}
            >
                {isUkrainian ? "Видалити" : "Delete"}
            </Button>
        </DialogActions>
    </Dialog>;
}

function getExitConfirmDialog(openLogout, setLogoutOpen, classes, userEmail, handleConfirmLogout, setOpenDelete, isUkrainian) {
    return <Dialog
        open={openLogout}
        onClose={() => {
            setLogoutOpen(false)
        }}
        classes={{paper: classes.modalForm}}
    >
        <DialogTitle classes={{root: classes.title}}>ReligionPeace Live</DialogTitle>
        <div style={{
            marginTop: "10px",
            marginBottom: "5px",
            height: 2,
            background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
        }}/>
        <DialogContent>
            <Typography>
                {isUkrainian ?
                    `Ви дійсно бажаєте вийти з облікового запису ${userEmail}?` :
                    `Do you really want to log out of your account ${userEmail}?`}
            </Typography>
        </DialogContent>
        <DialogActions style={{display: 'block'}}>
            <div className={classes.modalActions}>
                <Button
                    color={"primary"}
                    onClick={() => {
                        setLogoutOpen(false)
                    }}
                >
                    {isUkrainian ? "Скасувати" : "Cancel"}
                </Button>
                <Button
                    className={classes.confirmButton}
                    onClick={handleConfirmLogout}
                >
                    {isUkrainian ? "Вийти" : "Log out"}
                </Button>
            </div>
            <div style={{width: '70%', margin: '10px auto'}}>
                <Typography>
                    {isUkrainian ? "Ви також можете " : "You can also "}
                <span className={classes.deleteLabel} onClick={() => {
                    setLogoutOpen(false)
                    setOpenDelete(true)
                }}>
                    {isUkrainian ? "Видалити" : "Delete"}
                </span>
                    {isUkrainian ? " цей обліковий запис." : " this account"}
                </Typography>
            </div>
        </DialogActions>
    </Dialog>;
}

function getLanguageDialog(openClientMenu, handleCloseClientMenu, handleChooseUk, handleChooseEn, classes, isUkrainian) {
    return <Dialog
        open={openClientMenu}
        onClose={handleCloseClientMenu}
        classes={{paper: classes.modalForm}}
    >
        <DialogTitle classes={{root: classes.title}}>
            {isUkrainian ? "Виберіть мову" : "Choose language"}
        </DialogTitle>
        <div style={{
            marginTop: "10px",
            marginBottom: "5px",
            height: 2,
            background: "radial-gradient(ellipse at center, #fc9011 0,hsla(0,0%,100%,0) 75%)"
        }}/>
        <DialogContent
            style={{padding: '10px 50px 20px 50px', display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
            <Button
                style={{backgroundColor: isUkrainian ? "#f76c11" : "#eff2f7",
                        color: isUkrainian ? "white" : "black"
                }}
                onClick={handleChooseUk}
            >
                {isUkrainian ? "Українська" : "Ukrainian"}
            </Button>
            <Button
                style={{backgroundColor: isUkrainian ? "#eff2f7" : "#f76c11",
                        color: isUkrainian ? "black" : "white"
                }}
                onClick={handleChooseEn}
            >
                {isUkrainian ? "Англійська" : "English"}
            </Button>
        </DialogContent>
    </Dialog>;
}

const Header = () => {
  const classes = useStyles()
  const countNotifications = useSelector(state => state.notifications.countNewNotifications)
  const dispatch = useDispatch()
  const history = useHistory()
  const authorized = useSelector(state => state.user.authorized)
  const userEmail = useSelector(state => state.user.email)
  const [openLogout, setLogoutOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openSuccessDelete, setSuccessDeleteOpen] = useState(false)
  const [openSuccessLogout, setSuccessLogoutOpen] = useState(false)
  const [initState, setInitState] = useState(false)
  const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const language = useSelector(state => state.language.language)
  const [routes, setRoutes] = useState([])
  const [openClientMenu, setOpenClientMenu] = useState(false)

    useEffect(() => {
        if(language === "en"){
            setRoutes([
                { to: '/', name: 'Main', exact: true },
                { to: '/favorite', name: 'My Communities', exact: true },
                { to: '/live', name: 'Live Stream', exact: false },
                { to: '/balance', name: 'Balance', exact: true },
                { to: '/about', name: 'About Us', exact: true },
                { to: '/contact', name: 'Contacts', exact: true },
            ])
        }else{
            setRoutes([
                { to: '/', name: 'Головна', exact: true },
                { to: '/favorite', name: 'Мої громади', exact: true },
                { to: '/live', name: 'Пряма трансляція', exact: false },
                { to: '/balance', name: 'Баланс добра', exact: true },
                { to: '/about', name: 'Про нас', exact: true },
                { to: '/contact', name: 'Контакти', exact: true },
            ])
        }
        setInitState(true)
    }, [language])

  const handleMainPageLinkClick = () => {
      if(location.pathname === "/"){
          dispatch(setInitialState())
          dispatch(fetchMoreChurches())
      }else{
          dispatch(setInitialState())
      }
  }

    const handleConfirmLogout = () => {
        deSyncDevice().then(() => {
            dispatch(logout())
            setLogoutOpen(false)
            setSuccessLogoutOpen(true)
        })
    }

    const handleAccountClick = () => {
        if(authorized) {
            setLogoutOpen(true)
        }else {
            history.push("/authorization")
        }
    };

  const handleDeleteAccount = () => {
    deleteAccount().then(() => {
        setOpenDelete(false)
        setSuccessDeleteOpen(true)
    })
  }

  useEffect(() => {
    getNewNotifications().then((response) => {
      let count = response.count
      dispatch(setCountNewNotifications({count}))
    })
  }, [])

    const handleOpenClientMenu = () => {
        setOpenClientMenu(true)
    }

    const handleCloseClientMenu = () => {
        setOpenClientMenu(false)
    }

    const handleChooseUkr = () => {
        dispatch(setLanguage("ua"))
        setOpenClientMenu(false)
        setUserLanguage()
        location.reload()
    }

    const handleChooseEn = () => {
        dispatch(setLanguage("en"))
        setOpenClientMenu(false)
        setUserLanguage()
        location.reload()
    }

  return (
    <>
      {initState && (
          <>
              <AppBar position="fixed" className={classes.appBar}>
                  <Container disableGutters>
                      <Toolbar className={classes.toolbar}>
                          <Link
                              component={RouterLink}
                              to={'/'}
                              style={{position: 'absolute'}}
                              onClick={handleMainPageLinkClick}
                          >
                              <img src={logo} alt="logo" className={classes.logo}/>
                          </Link>
                          {mdUp ? (
                              <>
                                  <nav style={{margin: '0 auto'}}>
                                      <List style={{display: 'flex'}}>
                                          {routes.map(({to, name, exact}) => (
                                              <ListItem style={{whiteSpace: 'nowrap'}} key={to}>
                                                  <ListItemText>
                                                      <Link
                                                          onClick={to === '/' ? handleMainPageLinkClick : undefined}
                                                          to={to}
                                                          component={RouterLink}
                                                          className={classes.link}
                                                          activeClassName={classes.activeLink}
                                                          exact={exact}
                                                      >
                                                          {name}
                                                      </Link>
                                                  </ListItemText>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </nav>
                                  <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
                                      <Badge badgeContent={countNotifications} max={99} color="primary"
                                             classes={{colorPrimary: classes.badgeCounter}}>
                                          <NotificationsRoundedIcon fontSize={"medium"} htmlColor={'#f87f30'}
                                                                    className={classes.notifyIcon} onClick={() => {
                                              history.push('/notifications')
                                          }}/>
                                      </Badge>
                                      <AccountCircleIcon color="primary" className={classes.accountIcon}
                                                         onClick={handleAccountClick}/>
                                      <LanguageIcon onClick={handleOpenClientMenu} fontSize={"small"}
                                                    htmlColor={'#f87f30'}/>
                                  </div>
                              </>
                          ) : (
                              <div className={classes.menuIconButton}
                                   style={{
                                       justifyContent: location.pathname === "/" ? 'space-around' : 'flex-end',
                                       columnGap: location.pathname === "/" ? '0' : '10px'
                                   }}>
                                  <Badge badgeContent={countNotifications} max={99} color="primary"
                                         classes={{colorPrimary: classes.badgeCounter}}>
                                      <NotificationsRoundedIcon htmlColor={'#f87f30'} style={{
                                          backgroundColor: "transparent",
                                          fontSize: '2.5rem',
                                          marginRight: '-4px'
                                      }} onClick={() => history.push('/notifications')}/>
                                  </Badge>
                                  <Route exact path={'/'}>
                                    <FindChurchesByRadiusIcon/>
                                  </Route>
                                  <AccountCircleIcon htmlColor={"rgb(248, 127, 48)"} style={{fontSize: '2.5rem'}} onClick={handleAccountClick}/>
                                  <LanguageIcon onClick={handleOpenClientMenu} fontSize={"large"} htmlColor={'#f87f30'}/>
                                  <MobileMenuButton openLogout={() => setLogoutOpen(true)}/>
                              </div>
                          )}
                      </Toolbar>
                  </Container>
              </AppBar>
              <div className={classes.placeholder}/>
              {getLanguageDialog(openClientMenu, handleCloseClientMenu, handleChooseUkr, handleChooseEn, classes, language === "ua")}
              {getExitConfirmDialog(openLogout, setLogoutOpen, classes, userEmail, handleConfirmLogout, setOpenDelete, language === "ua")}
              {getConfirmDialog(openDelete, setOpenDelete, classes, userEmail, handleDeleteAccount, language === "ua")}
              {getSuccessDeleteDialog(openSuccessDelete, dispatch, setSuccessDeleteOpen, classes, userEmail, language === "ua")}
              {getExitSuccessDialog(openSuccessLogout, setSuccessLogoutOpen, classes, language === "ua")}
          </>
        )}
    </>
  )
}

export default Header

const MobileMenuButton = ({openLogout}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const {language} = useSelector(state => state.language)
  const initLanguage = useSelector(state => state.user.isLanguageLoaded)
  const [routes, setRoutes] = useState([])

    useEffect(() => {
        if(language !== "ua"){
            setRoutes([
                { to: '/', name: 'Main', exact: true },
                { to: '/favorite', name: 'My Communities', exact: true },
                { to: '/live', name: 'Live Stream', exact: false },
                { to: '/balance', name: 'Balance', exact: true },
                { to: '/about', name: 'About Us', exact: true },
                { to: '/contact', name: 'Contacts', exact: true },
            ])
        }else{
            setRoutes([
                { to: '/', name: 'Головна', exact: true },
                { to: '/favorite', name: 'Мої громади', exact: true },
                { to: '/live', name: 'Пряма трансляція', exact: false },
                { to: '/balance', name: 'Баланс добра', exact: true },
                { to: '/about', name: 'Про нас', exact: true },
                { to: '/contact', name: 'Контакти', exact: true },
            ])
        }
    }, [initLanguage])

    const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMainPageLinkClick = () => {
    dispatch(setInitialState())
    dispatch(fetchMoreChurches())
  }

  return (
    <>
      {initLanguage && (
         <>
           <IconButton aria-label={'menu button'} color={'primary'} onClick={handleDrawerOpen} classes={{root: classes.menuSign}}>
             <MenuIcon fontSize={"large"}/>
           </IconButton>
           <Drawer
               anchor="top"
               open={open}
               onClose={handleDrawerClose}
               classes={{
                 paper: classes.drawerPaper,
               }}
           >
             <List component={'nav'}>
               {routes.map(({ to, name }) => (
                   <ListItem key={to}>
                     <ListItemText style={{ textAlign: 'center' }}>
                       <Link
                           onClick={() => {
                             handleDrawerClose()
                             handleMainPageLinkClick()
                           }}
                           to={to}
                           className={classes.link}
                           component={RouterLink}
                           activeClassName={classes.activeLink}
                           exact
                       >
                         {name}
                       </Link>
                     </ListItemText>
                   </ListItem>
               ))}
             </List>
           </Drawer>
         </>
      )}
    </>
  )
}
